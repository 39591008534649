module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"jacob-software","accessToken":"MC5Yak5TX3hBQUFDTUFlbjRz.77-977-9S--_vUHvv71kZO-_vW4G77-977-9JGPvv709Ce-_vT3vv73vv71877-9Ge-_vQgV77-9V--_vV4","previews":false,"omitPrismicScript":true,"pages":[{"type":"Blog_post","match":"/blog/:uid","path":"/blog-preview","component":"/home/runner/work/jacob-software/jacob-software/src/templates/blogPost.js"}],"sharpKeys":[{}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
